import { FC, PropsWithChildren } from 'react';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { CurrencySymbol } from '@legacyApp/client/components/currencySymbol/currencySymbol';
import { TokenSymbol } from '@legacyApp/client/components/currencySymbol/tokenSymbol';
import { BalanceCurrencyWrapperStyle } from './balanceAmount/balanceCurrencyWrapper.style';

export const CurrencyValue: FC<
	PropsWithChildren<{
		currency?: CurrencyType;
		isCurrencyInFront?: boolean;
		isToken?: boolean;
		className?: string;
	}>
> = ({ children, currency, isCurrencyInFront, isToken, className }) => {
	return (
		<BalanceCurrencyWrapperStyle
			className={className}
			$isCurrency={!!currency}
			$isCurrencyInFront={isCurrencyInFront}
		>
			{currency &&
				(isToken ? (
					<TokenSymbol token={currency} />
				) : (
					<CurrencySymbol currency={currency} />
				))}
			{children}
		</BalanceCurrencyWrapperStyle>
	);
};
